export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status Lead',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,

  },
  {
    type: 'select',
    label: 'Programs',
    margin: true,
    showLabel: true,
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Owner',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
  },
  {
    type: 'select',
    label: 'Last Action',
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: 'All' },
      { value: 1, label: 'Pending' },
      { value: 2, label: 'Not Answer' },
      { value: 3, label: 'Closed' },
      { value: 4, label: 'Information requested' },

    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    type: 'select',
    label: 'Task',
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: 'All' },
      { value: 1, label: 'Attend' },
      { value: 2, label: 'Yes' },
      { value: 3, label: 'No' },
    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    type: 'select',
    label: 'Sale',
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: 'All' },
      { value: 2, label: 'Yes' },
      { value: 1, label: 'No' },

    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    md: 2,
    visible: true,
  },

]
